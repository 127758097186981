import React, { Component } from 'react'
//component
import RichContent from '../content/RichContent'
//scss
import '../../scss/block/marqueFonction.scss'
export default class MarqueFonction extends Component {
  state = {
    button: Array.apply(null, Array(this.props.fonction.length)),
  }

  buttonClick = (e) => {
    if (this.state.button[e.target.value] === 'active') {
      let table = this.state.button
      table[e.target.value] = ''
      this.setState({ button: table })
    } else {
      let table = this.state.button
      table[e.target.value] = 'active'
      this.setState({ button: table })
    }
  }
  render() {
    return (
      <div className='richContent fonctionSpeciale'>
        <h2>{this.props.title}</h2>
        {this.props.intro ? <p>{this.props.intro}</p> : ''}
        {this.props.fonction.map((item, index) => {
          return (
            <div
              key={index}
              className={`oneFonction ${
                this.state.button[index] === 'active' ? 'active' : ''
              }`}
            >
              <button value={index} onClick={this.buttonClick}>
                {item.title}
              </button>
              <RichContent myRichContent={item.content} />
            </div>
          )
        })}
      </div>
    )
  }
}
