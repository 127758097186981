import React from 'react'
import { graphql } from 'gatsby'

//content
import RichContent from '../components/content/RichContent'
//component
import MarqueFonction from '../components/block/MarqueFonction'
const TemplateMarque = ({ data }) => {
  return (
    <div className='service'>
      <div className='container'>
        <RichContent myRichContent={data.c.content} />
        {data.c.fonctionSpeciale ? (
          <MarqueFonction
            fonction={data.c.fonctionSpeciale}
            title={data.t.fonctionSpecialeTitle}
            intro={
              data.c.fonctionIntro ? data.c.fonctionIntro.fonctionIntro : ''
            }
          />
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export const query = graphql`
  query($id: String) {
    c: contentfulMarque(id: { eq: $id }) {
      id
      title
      content {
        json
      }
      fonctionIntro {
        fonctionIntro
      }
      fonctionSpeciale {
        title
        content {
          json
        }
      }
    }
    t: contentfulInfo {
      fonctionSpecialeTitle
    }
  }
`
export default TemplateMarque
